import PropTypes from "prop-types";
import { Combobox } from "@headlessui/react";
import classNames from "classnames";

export const Input = ({ displayValue, onChange, className, ...rest }) => (
  <Combobox.Input
    className={classNames(
      "bg-primary text-dark w-full cursor-pointer rounded-lg border-none py-2.5 pl-4 pr-10 text-base focus:ring-0",
      className,
    )}
    displayValue={displayValue}
    onChange={onChange}
    {...rest}
  />
);

Input.propTypes = {
  displayValue: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
