import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Combobox } from "@headlessui/react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";

export const ChevronDownButton = forwardRef(({ className }, ref) => (
  <Combobox.Button
    ref={ref}
    className={classNames(
      "text-dark absolute inset-y-0 right-0 flex items-center pr-2",
      className,
    )}
  >
    <Icon type="20-chevron-down-sm" size={20} />
  </Combobox.Button>
));
ChevronDownButton.displayName = "ChevronDownButton";

ChevronDownButton.propTypes = {
  className: PropTypes.string,
};
