import PropTypes from "prop-types";
import { SearchInput } from "./SearchInput";

export const SingleSelectInput = props => {
  const { selectedItems, open, handleOpen } = props;

  const selectedItem = selectedItems[0];
  if (open || !selectedItem) {
    return <SearchInput {...props} />;
  }
  return (
    <button
      type="button"
      onClick={handleOpen}
      className="flex w-full items-center text-base leading-6"
    >
      {selectedItem.text}
    </button>
  );
};

SingleSelectInput.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
};
