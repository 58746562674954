import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import type { RegisterOptions } from "react-hook-form";

export interface FormTextAreaProps
  extends ComponentPropsWithoutRef<"textarea"> {
  name: string;
  rules?: RegisterOptions;
  placeholder?: string;
  className?: string;
  rows?: number;
}

export const FormTextArea = ({
  name,
  rules = {},
  placeholder = "",
  className = "",
  rows = 4,
  ...rest
}: FormTextAreaProps) => {
  const { register } = useFormContext();

  return (
    <textarea
      {...rest}
      {...register(name, rules)}
      className={classNames(
        "form-control focus:!outline-secondary placeholder:text-light placeholder:opacity-100 focus:!outline-1 focus:!outline-offset-0",
        className,
      )}
      name={name}
      placeholder={placeholder}
      rows={rows}
    />
  );
};

FormTextArea.displayName = "Form.TextArea";
