import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxItem } from "../CheckboxGroup/CheckboxItem";

export const Checkbox = ({
  name,
  rules = {},
  label = "",
  description = "",
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CheckboxItem
          onChange={e => onChange(e.target.checked)}
          checked={value}
          option={{ label, description }}
          {...rest}
        />
      )}
    />
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.node,
  description: PropTypes.string,
};

Checkbox.displayName = "Form.Checkbox";
