import type { ReactNode } from "react";
import { forwardRef } from "react";
import classnames from "classnames";

export interface InputProps {
  className?: string;
  customOpacity?: string;
  disabled?: boolean;
  focusRequired?: boolean;
  hideBorder?: boolean;
  noPadding?: boolean;
  otherClassName?: string;
  prefix?: ReactNode;
  prefixWrapperClassName?: string;
  suffixWrapperClassName?: string;
  suffixInnerClassName?: string;
  required?: boolean;
  suffix?: ReactNode;
  variant?: "text" | "textarea";
  wrapperClassName?: string;
  [key: string]: any;
}

export type InputOrArea = HTMLInputElement | HTMLTextAreaElement;

export const Input = forwardRef<InputOrArea, InputProps>(
  (
    {
      className = "form-control",
      wrapperClassName,
      prefixWrapperClassName,
      suffixWrapperClassName,
      suffixInnerClassName,
      otherClassName,
      hideBorder = false,
      noPadding = false,
      prefix,
      suffix,
      variant = "text",
      disabled = false,
      focusRequired = true,
      customOpacity = "40",
      ...rest
    },
    ref,
  ) => {
    const Input: any = variant === "textarea" ? "textarea" : "input";

    return (
      <div
        className={classnames(
          "text-default flex w-full rounded-lg ring-current",
          {
            "focus-within:ring-1": focusRequired,
          },
          wrapperClassName,
        )}
      >
        {Boolean(prefix) && (
          <span
            className={classnames(
              "form-control__prefix",
              {
                [`cursor-not-allowed opacity-${String(customOpacity)}`]:
                  disabled,
              },
              prefixWrapperClassName,
            )}
          >
            {prefix}
          </span>
        )}
        <Input
          ref={ref}
          className={classnames(
            "placeholder:text-light placeholder:opacity-100",
            {
              "with-prefix": Boolean(prefix),
              "with-suffix": Boolean(suffix),
              "form-control--no-padding": noPadding,
              "form-control--no-border": hideBorder,
              [`cursor-not-allowed opacity-${String(customOpacity)}`]: disabled,
            },
            className,
            otherClassName,
          )}
          disabled={disabled}
          {...rest}
        />
        {Boolean(suffix) && (
          <div
            className={classnames(
              "form-control__suffix",
              {
                [`cursor-not-allowed opacity-${String(customOpacity)}`]:
                  disabled,
              },
              suffixWrapperClassName,
            )}
          >
            <span className={suffixInnerClassName}>{suffix}</span>
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
